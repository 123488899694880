.Footer {
  padding-bottom: 60px;
  background: #131210;
  font-family: 'Inter', sans-serif;
  text-align: center;

  &__container {
    color: #fff;

    h3 {
      margin: 0;
      padding-top: 40px;
      font-weight: 600;
    }

    &__secondRow,
    &__firstRow {
      margin: 30px auto;
      display: flex;
      justify-content: space-around;

      .item {
        display: flex;

        .icon {
          height: 20px;
          width: 20px;
          object-fit: contain;
          margin-right: 15px;
        }

        a {
          text-decoration: none;
          color: #fff;
        }
      }
    }

    &__firstRow {
      max-width: 550px;
    }

    &__secondRow {
      max-width: 600px;
    }
  }

  &__row {
    height: 7px;
    width: 3000%;
    position: relative;
    left: -100%;
    background: #BBB375;
  }
}

@media (max-width: 600px) {
  .Footer__container {
    h3 {
      font-size: 16px;
    }

    &__secondRow,
    &__firstRow {
      .item {
        a {
          font-size: 13px;
        }

        .icon {
          width: 17px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .Footer__container__firstRow {
    flex-direction: column;
    align-items: center;

    .phone {
      margin-bottom: 25px;
    }
  }
}