.quote-form {
  margin: 0 auto;
  padding: 20px;
  background-color: #dbccb2;
}
.quote-form h1 {
  text-align: center;
  margin-bottom: 20px;
}
.quote-form .scroll {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 100px;
  right: 40px;
  z-index: 1000;
}
.quote-form .scroll button {
  border-radius: 50%;
  border: 1px solid black;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
}
.quote-form .scroll img {
  width: 40%;
  height: 40%;
}
.quote-form .scroll__top img {
  transform: rotate(90deg);
}
.quote-form .scroll__bottom img {
  transform: rotate(-90deg);
}
.quote-form form {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.quote-form form label {
  margin-bottom: 10px;
}
.quote-form form input {
  width: 100%;
  padding: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.quote-form form select {
  height: 35px;
  text-indent: 6px;
}
.quote-form form button {
  width: 100%;
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.quote-form form a {
  color: #4caf50;
}

.error {
  color: #c83535 !important;
  text-align: center;
  margin-top: 0;
}/*# sourceMappingURL=quotesGenerator.css.map */