.customPageForm {

    select,
    input,
    textarea {
        width: 100%;
        padding: 8px 0;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    select {
        height: 30px;
    }

    .addItem {
        margin-bottom: 40px;
    }
}