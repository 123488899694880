.OurTeam{
    background: #dbccb2;
    text-align: center;
    padding-bottom: 100px;
    &__intro{
        padding: 50px 40px 0 40px;
        h1{
            color: #524D4D;
            font-size: 37px;
        }
        h2{
            font-size: 21px;
            font-weight: 300;
            color: #000;
            span{
                font-weight: 600;
                color: #79643C;
            }
        }
    }
}

.OurTeam__ourTeam{
    margin-top: 60px;
    h3{
        font-size: 21px;
        color: #524D4D;
    }
    &__cardContainer{
        margin: 100px 60px 0 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        .OurTeamCard{
            width: 40%;
            max-width: 500px;
            background-color: #524D4D;
            border-radius: 13px;
            margin-bottom: 60px;
            &__upper{
                display: flex;

                &__imgContainer{
                    width: 100px;
                    height: 100px;
                    padding: 20px 20px 0 20px;
                    img{
                        width: 100%;
                        height: 100%;
                        display: block;
                        object-fit: cover;
                        border-radius: 50%;

                    }
                }
                &__generalInfo{
                    text-align: left;
                    color: #BBB375;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 40px;
                    padding-right :20px;
                    .name{
                        margin: 0;
                        font-size: 25px;
                        font-weight: 600;
                    }
                    .role{
                        margin: 0;
                        font-size: 17px;
                        font-weight: 400;
                    }
                }
            }

            &__bottom{
                p{
                    text-align: center;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 300;
                    padding: 5px 35px 30px 30px;
                }
            }
        }
    }
}

@media (max-width: 1100px){
    .OurTeam__ourTeam__cardContainer{
        flex-direction: column;
        align-items: center;
        .OurTeamCard{
            max-width: none;
            width: 60%;
            &__upper{
                justify-content: center;
                flex-direction: column;
                align-items: center;
                &__imgContainer{
                    padding: 0;
                    margin: 20px 0;
                }

                &__generalInfo{
                    margin: 0;
                    padding: 0;
                    text-align: center;
                }
            }
            &__bottom{
                p{
                    text-align: center;
                    padding: 10px 50px 30px 50px;
                }
            }
        }
        
    }
}

@media (max-width: 750px) {
    .OurTeam__ourTeam__cardContainer{
        .OurTeamCard{
            width: 85%;
        }
    }
}

@media (max-width: 550px) {
    .OurTeam__intro{
        padding-top: 25px;
        h1{
            font-size: 26px;
        }
        h2{
            font-size: 17px;
        }
    }

    .OurTeam__ourTeam__cardContainer{
        margin: 30px 0 40px 0;
        .OurTeamCard{
            width: 90%;
        }
        .OurTeamCard__bottom{
            p{
                padding: 15px 30px;
            }
        }
    }

    
}