.basic {
  background-color: #dbccb2;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
}
.basic h1 {
  color: #333;
  font-size: 37px;
  padding-top: 60px;
  margin-top: 0;
  font-size: 30px;
  padding: 10px 20px 0 20px;
}
.basic h1:first-letter,
.basic h2:first-letter,
.basic h3:first-letter,
.basic p:first-letter {
  text-transform: capitalize;
}
.basic h1,
.basic h2,
.basic h3,
.basic p {
  line-height: 130%;
  text-align: justify;
  text-justify: distribute-all-lines;
  -moz-text-align-last: center;
       text-align-last: center;
}
.basic__customerName {
  text-align: center !important;
  text-justify: none;
  -moz-text-align-last: center;
       text-align-last: center;
  margin: 0 auto;
}
.basic__section__title {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 60px;
  max-width: 900px;
}
.basic__section__title h2,
.basic__section__title h3 {
  padding: 5px 20px 0 20px;
  color: #403737;
}
.basic__section__title h2 {
  margin-right: auto;
  text-align: left;
  font-weight: 500;
  font-size: 26px;
}
.basic__section__title h3 {
  margin-left: auto;
  text-align: right;
  font-weight: 400;
  font-size: 20px;
}
.basic__section__title .title3 {
  text-align: center;
  margin: 0 auto;
  padding-bottom: 15px;
  font-size: 30px;
  font-weight: 700;
  color: #333;
}
.basic__section__title.sm h2 {
  font-size: 18px;
}
.basic__section__title.sm h3 {
  font-size: 15px;
}
.basic__section__title.md h2 {
  font-size: 26px;
}
.basic__section__title.md h3 {
  font-size: 20px;
}
.basic__section__title.lg h2 {
  font-size: 32px;
}
.basic__section__title.lg h3 {
  font-size: 23px;
}
.basic__section__text {
  font-weight: 300;
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px 0;
}
.basic__section__text p {
  margin: 0;
  padding: 5px 40px;
}
.basic__section__text.sm p {
  font-size: 13px;
}
.basic__section__text.md p {
  font-size: 16px;
}
.basic__section__text.lg p {
  font-size: 23px;
}
.basic__section__text.xl p {
  font-size: 20px;
}
.basic__section__interruption {
  padding: 10px 0 30px 0;
}
.basic__section__interruption .row {
  height: 4px;
  margin: 0 auto;
}
.basic .error {
  color: #c83535 !important;
  text-align: center;
  margin-top: 0;
  padding-top: 80px;
  margin-bottom: 0;
  padding-bottom: 200px !important;
}

@media (max-width: 850px) {
  .basic h1 {
    font-size: 25px;
  }
  .basic__section__title {
    padding: 0 30px;
  }
  .basic__section__title h2,
  .basic__section__title h3 {
    padding: 10px;
  }
  .basic__section__title h2 {
    font-size: 20px;
  }
  .basic__section__title h3 {
    font-size: 16px;
  }
  .basic__section__title .title3 {
    font-size: 25px;
  }
}
@media (max-width: 600px) {
  .basic__section__text p {
    padding: 10px 20px;
  }
  .basic__section__text .text1 {
    font-size: 16px;
  }
  .basic__section__text .text2 {
    font-size: 13px;
  }
}
@media (max-width: 500px) {
  .basic h1 {
    font-size: 23px;
  }
  .basic__section__title .title3 {
    font-size: 20px;
  }
  .basic__section__title.lg h2 {
    font-size: 28px;
  }
  .basic__section__title.md h2 {
    font-size: 22px;
  }
  .basic__section__title.md h3 {
    font-size: 18px;
  }
}
@media (max-width: 400px) {
  .basic__section__title {
    padding: 0 10px;
  }
  .basic__section__title h2 {
    font-size: 20px !important;
  }
  .basic__section__title h3 {
    font-size: 16px !important;
  }
  .basic__section__text__text p {
    padding: 10px 20px;
  }
  .basic__section__text__text .text1 {
    font-size: 16px;
  }
  .basic__section__text__text .text2 {
    font-size: 13px;
  }
}/*# sourceMappingURL=basicPageStructure.css.map */