body,
html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: #131210;
  font-family: "Inter", sans-serif;
  margin: auto;
  position: relative;
}

.root {
  background-color: #dbccb2;
}

.App {
  margin: 0 auto;
  max-width: 1800px;
}

.background {
  position: absolute;
  left: -50%;
  width: 300%;
  height: calc(100% - 253px);
  background-color: #E7E5D8;
  z-index: -1;
}

.row {
  width: 80%;
  height: 3px;
  background-color: #BBB375;
  margin: 40px auto;
}

span {
  font-weight: 600;
}

@media (max-width: 1800px) {
  .background {
    display: none !important;
  }
}/*# sourceMappingURL=app.css.map */