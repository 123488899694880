.customPageForm select,
.customPageForm input,
.customPageForm textarea {
  width: 100%;
  padding: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.customPageForm select {
  height: 30px;
}
.customPageForm .addItem {
  margin-bottom: 40px;
}/*# sourceMappingURL=customPageForm.css.map */