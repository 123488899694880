.Home {
  max-width: 1800px;
  margin: 0 auto;
}
.Home__bgContainer {
  position: relative;
  width: 100%;
}
.Home__bgContainer img {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
  display: block;
}
.Home__bgContainer .filter {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.21);
}
.Home__bgText {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  font-family: "Inter", sans-serif;
  color: white;
}
.Home__bgText h1 {
  font-size: 37px;
  text-shadow: 2px 2px 8px rgba(38, 34, 34, 0.8784313725);
  font-weight: 800;
}
.Home__bgText h2 {
  font-size: 20px;
  font-weight: 200;
  margin-bottom: 5px;
}
.Home__bgText p {
  margin: 0;
  font-weight: 200;
  font-size: 13px;
  float: right;
}

@media (max-width: 1200px) {
  .Home__bgText p {
    float: none;
  }
}
@media (max-width: 600px) {
  .Home__bgText {
    top: 45%;
  }
  .Home__bgText h1 {
    font-size: 28px;
  }
  .Home__bgText h2 {
    font-size: 15px;
  }
}
@media (max-width: 500px) {
  .Home__bgText {
    top: 45%;
  }
  .Home__bgText h1 {
    font-size: 22px;
  }
  .Home__bgText h2 {
    font-size: 14px;
    margin-bottom: 7px;
    width: 325px;
  }
}/*# sourceMappingURL=home.css.map */