.quotesMenu {
  background-color: #dbccb2;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 200px;
}
.quotesMenu h1 {
  color: #333;
  font-size: 37px;
  padding-top: 60px;
  margin-top: 0;
  font-size: 30px;
  padding: 10px 20px 0 20px;
}
.quotesMenu a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  margin: 0 20px;
}
.quotesMenu a.active {
  background-color: #00cc66;
}
.quotesMenu__item {
  margin-bottom: 30px;
}/*# sourceMappingURL=adminMenu.css.map */