.qrView {
  background-color: #dbccb2;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 200px;
}
.qrView h1 {
  color: #333;
  font-size: 37px;
  padding-top: 60px;
  margin-top: 0;
  font-size: 30px;
  padding: 10px 20px 0 20px;
}
.qrView .download {
  margin: 0 10px;
}
.qrView__container {
  margin: 5cqi 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.qrView__container__card {
  background-color: #5b4d4d;
  width: 250px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 20px;
}
.qrView__container__card .title {
  color: rgb(189, 165, 70);
  font-weight: 600;
  margin: 0;
  padding: 15px 10px;
}
.qrView__container__card .link {
  word-wrap: break-word;
  color: white;
  font-size: 14px;
}
.qrView__container__card button,
.qrView__container__card a {
  padding: 10px 0;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  cursor: pointer;
}
.qrView__container__card input {
  padding: 10px 0;
  border-radius: 5px;
  text-indent: 10px;
  margin-bottom: 20px;
}
.qrView__container__card input:focus {
  outline: none;
}
.qrView__container__card button {
  border: none;
}
.qrView__container__card a {
  text-decoration: none;
  background-color: rgb(67, 163, 11);
  margin-bottom: 20px;
}/*# sourceMappingURL=qrView.css.map */