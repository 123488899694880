.Footer {
  padding-bottom: 60px;
  background: #131210;
  font-family: "Inter", sans-serif;
  text-align: center;
}
.Footer__container {
  color: #fff;
}
.Footer__container h3 {
  margin: 0;
  padding-top: 40px;
  font-weight: 600;
}
.Footer__container__secondRow, .Footer__container__firstRow {
  margin: 30px auto;
  display: flex;
  justify-content: space-around;
}
.Footer__container__secondRow .item, .Footer__container__firstRow .item {
  display: flex;
}
.Footer__container__secondRow .item .icon, .Footer__container__firstRow .item .icon {
  height: 20px;
  width: 20px;
  -o-object-fit: contain;
     object-fit: contain;
  margin-right: 15px;
}
.Footer__container__secondRow .item a, .Footer__container__firstRow .item a {
  text-decoration: none;
  color: #fff;
}
.Footer__container__firstRow {
  max-width: 550px;
}
.Footer__container__secondRow {
  max-width: 600px;
}
.Footer__row {
  height: 7px;
  width: 3000%;
  position: relative;
  left: -100%;
  background: #BBB375;
}

@media (max-width: 600px) {
  .Footer__container h3 {
    font-size: 16px;
  }
  .Footer__container__secondRow .item a, .Footer__container__firstRow .item a {
    font-size: 13px;
  }
  .Footer__container__secondRow .item .icon, .Footer__container__firstRow .item .icon {
    width: 17px;
  }
}
@media (max-width: 450px) {
  .Footer__container__firstRow {
    flex-direction: column;
    align-items: center;
  }
  .Footer__container__firstRow .phone {
    margin-bottom: 25px;
  }
}/*# sourceMappingURL=footer.css.map */