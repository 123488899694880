.quotesEditor {
  background-color: #dbccb2;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 200px;

  p {
    font-weight: 400 !important;
  }

  input {
    padding: 10px;
    margin: 20px 10px;
  }

  .scroll {
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 100px;
    right: 40px;
    z-index: 1000;

    button {
      border-radius: 50%;
      border: 1px solid black;
      margin-bottom: 10px;
      width: 50px;
      height: 50px;
    }

    img {
      width: 40%;
      height: 40%;
    }

    &__top {
      img {
        transform: rotate(90deg);
      }
    }

    &__bottom {
      img {
        transform: rotate(-90deg)
      }
    }
  }
}

.quotesEditor h1 {
  color: #333;
  font-size: 30px;
  margin-top: 0;
  padding: 10px 20px;
}

.quotesEditor .error {
  color: red;
}

.quotesEditor__display {
  display: flex;
  flex-direction: column;

  img {
    width: 200px;
  }

  &__item {
    margin-top: 20px;
  }
}

.quotesEditor__customerName,
.quotesEditor__name,
.quotesEditor__display {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.quotesEditor__customerName {
  margin-bottom: 50px;
}

.quotesEditor__customerName p {
  margin-right: 10px;
  font-weight: thin;
}

.quotesEditor__customerName button,
.quotesEditor__name button,
.display__button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: rgb(183, 90, 44);
}

.display__button {
  margin-bottom: 20px;
}

.quotesEditor__customerName button:hover {
  background-color: #555;
}

.quotesEditor__customerName .edit {
  margin-left: 10px;
}

.quotesEditor__customerName input[type="text"] {
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.quotesEditor__item {
  margin-bottom: 20px;
  background-color: #eae9ca;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quotesEditor__item p {
  margin: 5px 0;
  font-weight: bold;
}

.quotesEditor__item button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.quotesEditor__item button:hover {
  background-color: #555;
}

.quotesEditor__item textarea {
  margin-bottom: 10px;
  width: 90%;
  height: 100px;
  padding: 8px;
  border-radius: 4px;
}

.quotesEditor button.save {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.quotesEditor button.save:hover {
  background-color: #555;
}

.quotesEditor button.save:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.gallery__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 90vw;
  margin: 0 auto;
  padding-bottom: 40px;
}

.gallery__container__image {
  width: 200px;
  padding: 10px 20px;
}

.gallery__image {
  display: flex;
  flex-direction: column;

  button {
    background-color: red;
    margin: 0 auto;
    padding: 10px 20px;
  }

  button:hover {
    background-color: darkred;
  }
}

.quotesEditor__addItem label {
  display: block;
  margin-bottom: 10px;
}

.quotesEditor__addItem select,
.quotesEditor__addItem input[type="text"],
.quotesEditor__addItem textarea {
  display: block;
  width: 90%;
  padding: 8px;
  margin: 10px auto;
  font-size: 14px;
}

.quotesEditor__addItem select {
  background-color: #fff;
  border: 1px solid #ccc;
}

.quotesEditor__addItem input[type="text"],
.quotesEditor__addItem textarea {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
}

.quotesEditor__addItem p {
  margin-bottom: 10px;
}

.quotesEditor__addItem input[type="checkbox"] {
  margin-bottom: 10px;
}

.quotesEditor__addItem input[type="file"] {
  margin-bottom: 10px;
}

.quotesEditor__addItem .slider__preview {
  display: flex;
  flex-wrap: wrap;
}

.quotesEditor__addItem .gallery__image {
  width: 250px;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}

.quotesEditor__addItem .gallery__container__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.quotesEditor__addItem button[type="submit"] {
  padding: 8px 16px;
  background-color: #2a4fbd;
  color: white;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

/* Styles for iOS switch */
.quotesEditor__display {
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    height: auto;
    width: auto;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #a29191;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: #2196f3;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}