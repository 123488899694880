.langPrompt {
  position: absolute;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);

  &__content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    width: 30%;
    padding: 40px 0;
    border-radius: 10px;
    background-color: #444141;

    &__button {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: space-around;

      button {
        cursor: pointer;
        border: none;
        width: 100px;
        border-radius: 5px;
        padding: 5px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
}

@media (max-width: 1000px) {
  .langPrompt {
    &__content {
      width: 50%;
    }
  }
}

@media (max-width: 600px) {
  .langPrompt {
    &__content {
      width: 70%;
    }
  }
}

@media (max-width: 400px) {
  .langPrompt {
    &__content {
      width: 90%;
    }
  }
}