html {
  scroll-behavior: smooth;
}

.homes {
  background-color: #dbccb2;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
}
.homes .intro {
  text-align: left;
  padding: 40px;
  margin-top: 0;
  background-color: #1a1713;
  color: #fff;
  margin-top: -5px;
  /* :nth-child(odd) {
      text-align: right;
  } */
}
.homes section {
  position: relative;
}
.homes section .backgroundText {
  position: absolute;
  z-index: 20;
  color: white;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  font-family: "Prata", serif;
}
.homes section .backgroundText h1 {
  font-size: 3.2em;
  margin-bottom: 5px;
}
.homes section .backgroundText h2 {
  font-size: 1.3rem;
  margin-top: 0;
}
.homes section .backgroundImage {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 75vh;
  -o-object-fit: cover;
     object-fit: cover;
  filter: brightness(55%) contrast(0.9);
}
.homes section .arrow {
  position: absolute;
  bottom: 15px;
  width: 25px;
  left: 50%;
  transform: translateX(-50%) rotate(90deg);
  z-index: 100;
  filter: contrast(0.8);
  font-size: 2.5em;
  color: white;
  cursor: pointer;
}
.homes main {
  margin-top: 50px;
  margin-bottom: 40px;
}
.homes main h2 {
  font-family: "Prata", serif;
  font-size: 1.8em;
  color: #333;
  padding-bottom: 10px;
}
.homes main p {
  padding: 0 25px;
}
.homes main .homesContainer {
  margin-top: 40px;
}
.homes main .homesContainer .home {
  display: flex;
  text-align: left;
}
.homes main .homesContainer .home__img {
  flex: 0.35;
}
.homes main .homesContainer .home__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.homes main .homesContainer .home__text {
  flex: 0.65;
  margin: auto;
  margin-left: 25px;
}
.homes main .homesContainer .home__text img {
  display: none;
}
.homes main .homesContainer .home__text__title {
  display: flex;
  justify-content: space-between;
  padding-left: 0 !important;
  display: flex;
  align-items: end;
  margin-bottom: 25px;
}
.homes main .homesContainer .home__text__title h3 {
  font-size: 1.7em;
  font-family: "Prata", serif;
  padding: 0;
  margin: 0;
  text-align: left;
  padding-right: 20px;
}
.homes main .homesContainer .home__text__title img {
  display: block;
  text-align: center;
  height: 50px;
  float: right;
  margin-right: 20px;
  margin-top: 20px;
}
.homes main .homesContainer .home__text__title .suiteFlorencia_logo {
  height: 120px;
}
.homes main .homesContainer .home__text a {
  text-decoration: none;
  padding: 14px 40px;
  background-color: #512222;
  border-radius: 7px;
  float: right;
  margin: 20px 50px 0 0;
  color: white;
  font-size: 0.8em;
  transition: all 0.4s;
  margin-bottom: 20px;
}
.homes main .homesContainer .home__text div {
  margin-left: 25px;
  margin-top: 10px;
  margin-right: 25px;
  text-align: center;
  font-size: 0.75em;
  padding: 0 10px;
}
.homes main .homesContainer .home__text a:hover {
  background-color: #6a3737;
}
.homes main .homesContainer > .home:nth-child(even) {
  flex-direction: row-reverse;
}
.homes main .homesContainer > .home:first-child {
  border-bottom: 3px solid #512222;
}

@media (max-width: 1100px) {
  .homes main .homesContainer .home__img {
    flex: 0.35;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .homes main .homesContainer .home__img img {
    height: 60%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: 40%;
       object-position: 40%;
  }
  .homes main .homesContainer .home__text p {
    font-size: 0.9em;
  }
}
@media (max-width: 700px) {
  .homes section .backgroundText h1 {
    width: 80vw;
    font-size: 2.3em;
  }
  .homes section .backgroundText h2 {
    font-size: 1.1rem;
  }
}
@media (max-width: 650px) {
  .homes main .homesContainer .home {
    display: block;
  }
  .homes main .homesContainer .home__img {
    display: none;
  }
  .homes main .homesContainer .home__text {
    margin: 0;
    padding-bottom: 90px;
  }
  .homes main .homesContainer .home__text img {
    display: block;
    margin: auto;
    margin-top: 20px;
    width: 100%;
  }
  .homes main .homesContainer .home__text__title {
    margin-right: 0 !important;
  }
  .homes main .homesContainer .home__text__title h3 {
    font-size: 1.4em;
  }
  .homes main .homesContainer .home__text__title img {
    width: auto;
    height: 40px;
    margin-right: 0;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (max-width: 450px) {
  .homes main .homesContainer .home {
    text-align: center;
  }
  .homes main .homesContainer .home .suiteFlorencia_logo {
    height: 90px;
  }
  .homes main .homesContainer .home h3 {
    padding-bottom: 0 !important;
    font-size: 1.5em;
  }
  .homes main .homesContainer .home a {
    float: none;
    margin: 0 auto;
  }
  .homes main .homesContainer .home__text {
    padding-bottom: 40px;
  }
  .homes main .homesContainer .home__text :nth-last-child(2) {
    padding-bottom: 20px;
  }
}/*# sourceMappingURL=homes.css.map */