.Contacts {
  text-align: center;
  background-color: #dbccb2;
}
.Contacts h1 {
  color: #524D4D;
  font-size: 37px;
  padding-top: 60px;
  margin-top: 0;
}
.Contacts p {
  padding: 0 30px;
}
.Contacts__card {
  background-color: #2C2C2C;
  color: #BBB375;
  width: 60%;
  margin: auto;
  font-family: "Enriqueta", sans-serif;
  text-align: left;
  border-radius: 10px;
  margin-top: 55px;
  max-width: 700px;
}
.Contacts__card p {
  padding: 0;
}
.Contacts__card__upper {
  padding: 10px 0 0 60px;
}
.Contacts__card__upper .name {
  margin-bottom: 0;
  font-size: 34px;
  font-weight: 700;
}
.Contacts__card__upper .role {
  font-weight: 500;
  font-size: 24px;
  margin-top: 10px;
}
.Contacts__card__bottom {
  margin-top: 80px;
  margin-bottom: 40px;
  padding-bottom: 45px;
}
.Contacts__card__bottom .item {
  margin-left: 60px;
  display: flex;
  font-size: 23px;
  margin-bottom: 12px;
}
.Contacts__card__bottom .item p {
  margin: 0 20px 0 0;
}
.Contacts__card__bottom .item a {
  color: #BBB375;
}
.Contacts .download {
  margin-top: -10px;
  font-weight: 300;
  color: #000;
  display: block;
}
.Contacts .spaceFiller {
  padding-bottom: 100px;
}

@media (max-width: 850px) {
  .Contacts__card {
    width: 80%;
  }
}
@media (max-width: 600px) {
  .Contacts__card {
    width: 90%;
  }
  .Contacts__card__upper {
    text-align: center;
    padding: 0;
    padding-top: 10px;
  }
  .Contacts__card__upper .name {
    font-size: 28px;
  }
  .Contacts__card__upper .role {
    font-size: 22px;
  }
  .Contacts__card__bottom {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
  }
  .Contacts__card__bottom .item {
    margin: 0 auto 12px auto;
    font-size: 18px;
  }
}/*# sourceMappingURL=contacts.css.map */