.apartment {
    background-color: #dbccb2;
    text-align: center;
    position: relative;
    padding-bottom: 20px;

    h1 {
        color: #333;
        font-family: "Prata", serif;
        font-size: 37px;
        margin-top: 0;
        font-size: 30px;
        padding: 40px 20px 0 20px;
        margin-bottom: 0;
    }

    .intro {
        text-align: left;
        font-size: 15px;
        padding: 20px 30px;
        margin-bottom: 0;
        font-family: Georgia, 'Times New Roman', Times, serif;

        i {
            text-align: center;
            display: flex;
            justify-content: center;
            padding: 10px;
            font-size: 18px;
            font-weight: 200;
        }
    }
}

@media (max-width: 600px) {
    .apartment {
        h1 {
            font-size: 25px;
        }

        .intro i,
        .intro {
            font-size: 14px;
            padding-bottom: 0;
        }
    }
}