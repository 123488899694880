.homesDepliant {
  background-color: #dbccb2;
  text-align: center;
  position: relative;
  padding-bottom: 20px;
}
.homesDepliant h1 {
  color: #333;
  font-family: "Prata", serif;
  font-size: 37px;
  margin-top: 0;
  font-size: 30px;
  padding: 40px 20px 0 20px;
  margin-bottom: 0;
}
.homesDepliant .flipbookContainer {
  margin: 40px auto 0 auto;
  width: 90vw !important;
  border: 5px solid #704f33;
  max-width: 1400px !important;
  min-width: 300px !important;
}
.homesDepliant button {
  background-color: #704f33;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 20px;
  margin-top: 20px;
  cursor: pointer;
  margin: 25px 20px;
  border-radius: 5px;
}
.homesDepliant button:hover {
  background-color: #5a3b1f;
}
.homesDepliant button:disabled {
  cursor: not-allowed;
  background-color: rgba(117, 84, 69, 0.768627451);
}

.mobile {
  display: none;
}

@media (max-width: 800px) {
  .desktop {
    display: none;
  }
  .pdfContainer {
    margin: 30px auto;
    width: 95%;
    height: 60vh;
    max-height: 600px;
  }
  .mobile {
    display: block;
  }
  .mobile p {
    font-size: 14px;
  }
  .mobile iframe {
    margin-top: 40px;
    width: 100%;
    height: 60vh;
    min-height: 400px;
  }
}
@media (max-width: 600px) {
  .mobile {
    flex-direction: column;
  }
  .mobile img {
    width: 100%;
  }
  .mobile .text {
    padding: 0 25px;
    margin-top: 40px;
  }
  .mobile a {
    width: 80%;
  }
}/*# sourceMappingURL=homesDepliant.css.map */