.tourCard {
  position: relative;
  width: 400px;
  height: 180px;
  padding: 10px 20px;
  margin: 0 20px;
  text-decoration: none;
  text-align: left;
  border-radius: 10px;
  margin-bottom: 30px;
}
.tourCard p {
  font-size: 20px;
  font-weight: 500;
  position: relative;
  z-index: 3;
  color: #fff;
  padding: 0 10px;
}
.tourCard__imgContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0 auto;
  background-color: #ccc;
}
.tourCard__imgContainer .filter {
  position: absolute;
  top: 0;
  background-color: rgba(36, 35, 35, 0.5647058824);
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.tourCard__imgContainer img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  z-index: 1;
  border-radius: 10px;
}
.tourCard__discover {
  position: absolute;
  bottom: 18px;
  right: 25px;
}
.tourCard__discover p {
  font-size: 13px;
  margin: 0;
  padding: 10px 15px;
  border-radius: 30px;
  background: #000;
  text-decoration: none !important;
}
.tourCard__description {
  font-size: 16px !important;
  font-weight: 300 !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tourCard:hover .tourCard__discover p {
  background: #938557;
}

@media (max-width: 500px) {
  .tourCard {
    width: 90vw;
    height: 180px;
    margin: 0 0 30px 0;
    padding: 0 auto;
  }
  .tourCard__description {
    font-size: 15px !important;
    margin-top: -10px;
    -webkit-line-clamp: 4;
  }
}
@media (max-width: 400px) {
  .tourCard {
    height: 160px;
  }
  .tourCard__description {
    -webkit-line-clamp: 3;
  }
}/*# sourceMappingURL=tourCard.css.map */