.tours {
  background-color: #dbccb2;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}
.tours h1 {
  color: #333;
  font-size: 37px;
  padding-top: 60px;
  margin-top: 0;
  font-size: 30px;
  padding: 10px 20px 0 20px;
}
.tours__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}
.tours .error {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 40px;
}/*# sourceMappingURL=tours.css.map */