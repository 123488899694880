@keyframes loadingAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  flex-direction: column;
  z-index: 9999;
}

.loading {
  width: 50px;
  height: 50px;
  border: 3px solid #007bff;
  border-radius: 50%;
  border-top-color: transparent;
  margin-bottom: 10px;
  animation: loadingAnimation 1s linear infinite;
}

.loading-text {
  font-size: 16px;
  color: #fff;
}/*# sourceMappingURL=loading.css.map */