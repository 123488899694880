.basic {
  background-color: #dbccb2;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;

  h1 {
    color: #333;
    font-size: 37px;
    padding-top: 60px;
    margin-top: 0;
    font-size: 30px;
    padding: 10px 20px 0 20px;
  }

  h1:first-letter,
  h2:first-letter,
  h3:first-letter,
  p:first-letter {
    text-transform: capitalize;
  }

  h1,
  h2,
  h3,
  p {
    line-height: 130%;
    text-align: justify;
    text-justify: distribute-all-lines;
    text-align-last: center;
  }



  &__customerName {
    text-align: center !important;
    text-justify: none;
    text-align-last: center;
    margin: 0 auto;
  }

  &__section {
    &__title {
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 0 60px;
      max-width: 900px;

      h2,
      h3 {
        padding: 5px 20px 0 20px;
        color: #403737;
      }

      h2 {
        margin-right: auto;
        text-align: left;
        font-weight: 500;
        font-size: 26px;
      }

      h3 {
        margin-left: auto;
        text-align: right;
        font-weight: 400;
        font-size: 20px;
      }

      .title3 {
        text-align: center;
        margin: 0 auto;
        padding-bottom: 15px;
        font-size: 30px;
        font-weight: 700;
        color: #333;
      }
    }

    &__title.sm {
      h2 {
        font-size: 18px;
      }

      h3 {
        font-size: 15px;
      }
    }

    &__title.md {
      h2 {
        font-size: 26px;
      }

      h3 {
        font-size: 20px;
      }
    }

    &__title.lg {
      h2 {
        font-size: 32px;
      }

      h3 {
        font-size: 23px;
      }
    }

    &__text {
      font-weight: 300;
      max-width: 1000px;
      margin: 0 auto;
      padding: 10px 0;

      p {
        margin: 0;
        padding: 5px 40px;
      }
    }

    &__text.sm {
      p {
        font-size: 13px;
      }
    }

    &__text.md {
      p {
        font-size: 16px;
      }
    }

    &__text.lg {
      p {
        font-size: 23px;
      }
    }

    &__text.xl {
      p {
        font-size: 20px;
      }
    }

    &__interruption {
      padding: 10px 0 30px 0;

      .row {
        height: 4px;
        margin: 0 auto;
      }
    }
  }

  .error {
    color: #c83535 !important;
    text-align: center;
    margin-top: 0;
    padding-top: 80px;
    margin-bottom: 0;
    padding-bottom: 200px !important;
  }
}

@media (max-width: 850px) {
  .basic {
    h1 {
      font-size: 25px;
    }

    &__section {
      &__title {
        padding: 0 30px;

        h2,
        h3 {
          padding: 10px;
        }

        h2 {
          font-size: 20px;
        }

        h3 {
          font-size: 16px;
        }

        .title3 {
          font-size: 25px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .basic {
    &__section {
      &__text {
        p {
          padding: 10px 20px;
        }

        .text1 {
          font-size: 16px
        }

        .text2 {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .basic {
    h1 {
      font-size: 23px;
    }

    &__section__title {
      .title3 {
        font-size: 20px;
      }
    }

    &__section__title.lg {
      h2 {
        font-size: 28px;
      }
    }

    &__section__title.md {
      h2 {
        font-size: 22px;
      }

      h3 {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 400px) {
  .basic {
    &__section {
      &__title {
        padding: 0 10px;

        h2 {
          font-size: 20px !important;
        }

        h3 {
          font-size: 16px !important;
        }
      }

      &__text {
        &__text {
          p {
            padding: 10px 20px;
          }

          .text1 {
            font-size: 16px
          }

          .text2 {
            font-size: 13px;
          }
        }
      }
    }
  }
}