.aboutCuba {
  background-color: #dbccb2;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}
.aboutCuba h1 {
  color: #333;
  font-size: 37px;
  margin-top: 0;
  font-size: 30px;
  padding: 20px 20px 0 20px;
  margin-bottom: 0;
}/*# sourceMappingURL=aboutCuba.css.map */