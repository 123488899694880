.item-list {
  margin: 20px 0;
}
.item-list .item {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #eae9ca;
  cursor: grab;
}
.item-list .removeItem {
  background-color: rgb(208, 43, 43);
}/*# sourceMappingURL=customPageItemList.css.map */