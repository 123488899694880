.quotesView {
  background-color: #dbccb2;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 100px;
}
.quotesView h1 {
  color: #333;
  font-size: 37px;
  padding-top: 60px;
  margin-top: 0;
  font-size: 30px;
  padding: 10px 20px 0 20px;
}
.quotesView p {
  color: aliceblue;
}
.quotesView .error {
  padding-top: 10px;
  font-size: 20px;
  font-weight: 600;
}

.quoteContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 80px;
}

.quoteView {
  padding: 20px 40px;
  min-width: 300px;
  margin: 20px;
  border-radius: 10px;
  background-color: #5b4d4d;
  display: flex;
  flex-direction: column;
}
.quoteView__edit, .quoteView__quote {
  margin-top: 20px;
  padding: 7px 15px;
  background-color: orange;
  color: white !important;
  border-radius: 3px;
  text-decoration: none;
}
.quoteView__edit:hover {
  background-color: rgb(177, 112, 14);
}
.quoteView__quote {
  background-color: green;
}
.quoteView__quote:hover {
  background-color: darkgreen;
}

.delete,
.duplicate {
  background-color: red;
  color: white;
  padding: 8px 16px;
  margin-top: 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.duplicate {
  background-color: rgb(21, 153, 153);
}

.duplicate:hover {
  background-color: rgb(16, 122, 122);
}

.delete:hover {
  background-color: darkred;
}/*# sourceMappingURL=quotesView.css.map */