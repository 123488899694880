.santaClaraPage {
  background-color: #dbccb2;
  text-align: center;
  padding-top: 30px;

  h1 {
    color: #333;
    font-size: 37px;
    margin-top: 0;
    font-size: 30px;
    padding: 20px 20px 0 20px;
    margin: 0;
    margin-bottom: -40px;
  }
}

@media (max-width: 800px) {
  .santaClaraPage {
    h1 {
      font-size: 25px;
    }
  }
}

@media (max-width: 400px) {
  .santaClaraPage {
    h1 {
      font-size: 21px;
    }
  }
}