.item-list {
    margin: 20px 0;

    .item {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #eae9ca;
        cursor: grab;
    }

    .removeItem {
        background-color: rgb(208, 43, 43);
    }
}