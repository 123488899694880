.santaClara {
  font-family: "Inter", sans-serif;
  background-color: #dbccb2;
  text-align: center;
  padding-bottom: 60px;
}
.santaClara h1 {
  padding-top: 50px;
  margin-top: 0;
  margin-bottom: 10px;
  color: #524D4D;
  font-size: 35px;
}
.santaClara h3 {
  margin-top: 0 !important;
  font-weight: 400;
  padding: 0 20px;
  margin-bottom: 30px;
}
.santaClara__sections {
  margin: 40px 40px 0 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 2000px;
  text-align: left;
}
.santaClara__sections__section {
  position: relative;
  display: block;
  width: 500px;
  height: 200px;
  margin: 0 20px 40px 20px;
}
.santaClara__sections__section h2, .santaClara__sections__section p {
  padding-left: 25px;
  position: absolute;
  z-index: 3;
  color: white;
}
.santaClara__sections__section h2 {
  text-decoration: underline;
}
.santaClara__sections__section p {
  margin-top: 57px;
  padding-right: 20px;
}
.santaClara__sections__section__container {
  width: 100%;
  height: 100%;
}
.santaClara__sections__section__container .filter {
  border-radius: 10px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5215686275);
}
.santaClara__sections__section__container img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.santaClara__sections__section .button {
  position: absolute;
  bottom: 60px;
  right: 110px;
}
.santaClara__sections__section .button p {
  font-size: 13px;
  margin: 0;
  padding: 10px 15px;
  border-radius: 30px;
  background: #000;
  text-decoration: none !important;
}
.santaClara__sections__section:hover .button p {
  background: #938557;
}

@media (max-width: 550px) {
  .santaClara__sections {
    margin: 0;
  }
}
@media (max-width: 400px) {
  .santaClara h1 {
    font-size: 30px;
  }
  .santaClara h3 {
    font-size: 16px;
  }
  .santaClara__sections__section p {
    font-size: 14px;
  }
}/*# sourceMappingURL=santaClara.css.map */