.quote-form {
  margin: 0 auto;
  padding: 20px;
  background-color: #dbccb2;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .scroll {
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 100px;
    right: 40px;
    z-index: 1000;

    button {
      border-radius: 50%;
      border: 1px solid black;
      margin-bottom: 10px;
      width: 50px;
      height: 50px;
    }

    img {
      width: 40%;
      height: 40%;
    }

    &__top {
      img {
        transform: rotate(90deg);
      }
    }

    &__bottom {
      img {
        transform: rotate(-90deg)
      }
    }
  }

  form {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 10px;
    }

    input {
      width: 100%;
      padding: 8px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    select {
      height: 35px;
      text-indent: 6px;
    }

    button {
      width: 100%;
      margin-top: 10px;
      padding: 8px 16px;
      background-color: #4caf50;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    a {
      color: #4caf50;
    }
  }
}


.error {
  color: #c83535 !important;
  text-align: center;
  margin-top: 0;
}