.santaClara {
    font-family: 'Inter', sans-serif;
    background-color: #dbccb2;
    text-align: center;
    padding-bottom: 60px;

    h1{
        padding-top: 50px;
        margin-top: 0;
        margin-bottom: 10px;
        color: #524D4D;
        font-size: 35px;
    }
    h3{
        margin-top: 0 !important;
        font-weight: 400;
        padding: 0 20px;
        margin-bottom: 30px;
    }

    &__sections{
        margin: 40px 40px 0 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 2000px;
        text-align: left;
        &__section{
            position: relative;
            display: block;
            width: 500px;
            height: 200px;
            margin: 0 20px 40px 20px;
            h2, p {
                padding-left: 25px;
                position: absolute;
                z-index: 3;
                color: white;
            }
            h2{
                text-decoration: underline;
            }
            p{
                margin-top: 57px;
                padding-right: 20px;
            }
            &__container{
                width: 100%;
                height: 100%;
                .filter {
                    border-radius: 10px;
                    position: absolute;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    background: #00000085;
                }
                img{
                    border-radius: 10px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .button{
                position: absolute;
                bottom: 60px;
                right: 110px;
                p{
                    font-size: 13px;
                    margin: 0;
                    padding: 10px 15px;
                    border-radius: 30px;
                    background: #000;
                    text-decoration: none !important;
                }
            }
        }
        &__section:hover{
            .button{
                p{
                    background: #938557;
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .santaClara{
        &__sections{
            margin: 0;
        }
    }
}

@media (max-width: 400px) {
    .santaClara{
        h1{
            font-size: 30px; 
        }
        h3{
            font-size: 16px;
        }
        &__sections__section{
            p{
                font-size: 14px;
            }
        }
    }
}