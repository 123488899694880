.Contacts{
    text-align: center;
    background-color: #dbccb2;
    h1{
        color: #524D4D;
        font-size: 37px;
        padding-top: 60px;
        margin-top: 0;
    }
    p{
        padding: 0 30px;
    }

    &__card{
        background-color: #2C2C2C;
        color: #BBB375;
        width: 60%;
        margin: auto;
        font-family: 'Enriqueta', sans-serif;
        text-align: left;
        border-radius: 10px;
        margin-top: 55px;
        max-width: 700px;
        
        p{
            padding: 0;
        }

        &__upper{
            padding: 10px 0 0 60px;
            .name{
                margin-bottom: 0;
                font-size: 34px;
                font-weight: 700;
            }
            .role{
                font-weight: 500;
                font-size: 24px;
                margin-top: 10px;
            }
        }

        &__bottom{
            margin-top: 80px;
            margin-bottom: 40px;
            padding-bottom: 45px;
            .item{
                margin-left: 60px;
                display: flex;
                font-size: 23px;
                margin-bottom: 12px;
                p{
                    margin: 0 20px 0 0;
                }
                a{
                    color: #BBB375;
                }
            }
        }
    }

    .download{  
        margin-top: -10px;
        font-weight: 300;
        color: #000;
        display: block;
    }
    .spaceFiller{
        padding-bottom: 100px;
    }
}

@media (max-width: 850px) {
    .Contacts{
        &__card{
            width: 80%;
        }
    }
}

@media (max-width: 600px) {
    .Contacts{
        &__card{
            width: 90%;
            &__upper{
                text-align: center;
                padding: 0;
                padding-top: 10px;
                .name{
                    font-size: 28px;
                }
                .role{
                    font-size: 22px;
                }
            }
            &__bottom{
                margin-top: 50px;
                display: flex;
                flex-direction: column;
                .item{
                    margin: 0 auto 12px auto;
                    font-size: 18px;
                }
            }
        }
    }
}