.quote {
  background-color: #dbccb2;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}
.quote h1 {
  color: #333;
  font-size: 37px;
  padding-top: 60px;
  margin-top: 0;
  font-size: 30px;
  padding: 10px 20px 0 20px;
}
.quote h1:first-letter,
.quote h2:first-letter,
.quote h3:first-letter,
.quote p:first-letter {
  text-transform: capitalize;
}
.quote h1,
.quote h2,
.quote h3,
.quote p {
  line-height: 130%;
  text-align: justify;
  text-justify: distribute-all-lines;
  -moz-text-align-last: center;
       text-align-last: center;
}
.quote__customerName {
  text-align: center !important;
  text-justify: none;
  -moz-text-align-last: center;
       text-align-last: center;
  margin: 0 auto;
}
.quote__section__title {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 0 60px;
  max-width: 900px;
}
.quote__section__title h2,
.quote__section__title h3 {
  padding: 5px 20px 0 20px;
  color: #403737;
}
.quote__section__title h2 {
  margin-right: auto;
  text-align: left;
  font-weight: 500;
  font-size: 26px;
}
.quote__section__title h3 {
  margin-left: auto;
  text-align: right;
  font-weight: 400;
  font-size: 20px;
}
.quote__section__title .title3 {
  text-align: center;
  margin: 0 auto;
  padding-bottom: 15px;
  font-size: 30px;
  font-weight: 700;
  color: #333;
}
.quote__section__title.sm h2 {
  font-size: 18px;
}
.quote__section__title.sm h3 {
  font-size: 15px;
}
.quote__section__title.md h2 {
  font-size: 26px;
}
.quote__section__title.md h3 {
  font-size: 20px;
}
.quote__section__title.lg h2 {
  font-size: 32px;
}
.quote__section__title.lg h3 {
  font-size: 23px;
}
.quote__section__text {
  font-weight: 300;
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px 0;
}
.quote__section__text p {
  margin: 0;
  padding: 5px 40px;
}
.quote__section__text.sm p {
  font-size: 13px;
}
.quote__section__text.md p {
  font-size: 16px;
}
.quote__section__text.lg p {
  font-size: 23px;
}
.quote__section__text.xl p {
  font-size: 20px;
}
.quote__section__interruption {
  padding: 10px 0 30px 0;
}
.quote__section__interruption .row {
  height: 4px;
  margin: 0 auto;
}
.quote__download {
  padding: 0 40px;
}
.quote__download p {
  margin-bottom: 5px;
}
.quote__download button {
  background-color: transparent;
  text-decoration: underline;
  border: none;
  font-size: 16px;
  color: #484442;
  cursor: pointer;
}
.quote .error {
  color: #c83535 !important;
  text-align: center;
  margin-top: 0;
  padding-top: 80px;
  margin-bottom: 0;
  padding-bottom: 200px !important;
}

@media (max-width: 850px) {
  .quote h1 {
    font-size: 25px;
  }
  .quote__section__title {
    padding: 0 30px;
  }
  .quote__section__title h2,
  .quote__section__title h3 {
    padding: 10px;
  }
  .quote__section__title h2 {
    font-size: 20px;
  }
  .quote__section__title h3 {
    font-size: 16px;
  }
  .quote__section__title .title3 {
    font-size: 25px;
  }
}
@media (max-width: 600px) {
  .quote__section__text p {
    padding: 10px 20px;
  }
}
@media (max-width: 500px) {
  .quote h1 {
    font-size: 23px;
  }
  .quote__section__title .title3 {
    font-size: 20px;
  }
  .quote__section__text.md p {
    font-size: 15px;
  }
}
@media (max-width: 400px) {
  .quote__section__title {
    padding: 0 10px;
  }
  .quote__section__title.md h2 {
    font-size: 18px;
  }
  .quote__section__title.md h3 {
    font-size: 14px;
  }
  .quote__section__text__text p {
    padding: 10px 20px;
  }
}
@media print {
  @page {
    size: A4;
  }
  .quote {
    background-color: white;
  }
  body {
    background-color: transparent;
  }
  .quote__section {
    page-break-inside: avoid;
  }
  p {
    font-size: 12px !important;
  }
  .Menu {
    display: none;
  }
  .Footer {
    display: none;
  }
  .noPrint {
    display: none;
  }
  .section-to-print,
  .section-to-print * {
    visibility: visible;
  }
  .quote__section__title h2 {
    margin: 0 !important;
  }
  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}/*# sourceMappingURL=quote.css.map */