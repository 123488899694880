.AboutUs {
  background-color: #dbccb2;
  text-align: center;
  padding-bottom: 100px;
  margin-bottom: 0;
}
.AboutUs h1 {
  color: #524D4D;
  font-size: 37px;
  padding-top: 60px;
  margin-bottom: 80px;
  margin-top: 0;
}
.AboutUs__review {
  margin: 0 60px;
}
.AboutUs__review p {
  padding: 0 40px;
  max-width: 1000px;
  margin: 0 auto;
}
.AboutUs__review img {
  display: block;
  margin: 0 auto;
}
.AboutUs__review .articleInfo {
  display: flex;
  justify-content: right;
  margin: 20px 0 50px 0;
}
.AboutUs__review .articleInfo__container {
  width: 30%;
  max-width: 350px;
  min-width: 250px;
}
.AboutUs__review .articleInfo__container .logo {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.AboutUs__review .articleInfo__container .name {
  float: right;
  margin: 0;
  padding: 0;
  margin-top: 8px;
}
.AboutUs__review span {
  font-weight: 500;
}
.AboutUs .row {
  width: 80%;
  height: 4px;
  background-color: #BBB375;
  margin: 60px auto;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

@media (max-width: 850px) {
  .AboutUs__review .picture {
    width: 100%;
  }
  .AboutUs__review .articleInfo {
    justify-content: center;
    margin-bottom: 20px;
  }
  .AboutUs__review .articleInfo .name {
    float: none;
  }
}
@media (max-width: 650px) {
  .AboutUs__review {
    margin: 0 30px;
  }
  .AboutUs__review p {
    padding-left: 5px;
    padding-right: 5px;
  }
}/*# sourceMappingURL=aboutUs.css.map */