.Home{
    max-width: 1800px;
    margin: 0 auto;
    &__bgContainer{
        position: relative;
        width: 100%;
        img{
            width: 100%;
            height: 100%;
            max-height: 80vh;
            object-fit: cover;
            object-position: top;
            display: block;
        }
        .filter{
            position: absolute;
            top: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.21);
        }
    }

    &__bgText{
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        text-align: center;
        font-family: 'Inter', sans-serif;
        color: white;
        h1{
            font-size: 37px;
            text-shadow: 2px 2px 8px #262222e0;
            font-weight: 800;
        }
        h2{
            font-size: 20px;
            font-weight: 200;
            margin-bottom: 5px;
        }
        p{
            margin: 0;
            font-weight: 200;
            font-size: 13px;
            float: right;
        }
    }
}

@media (max-width: 1200px){
    .Home__bgText{
        p{
            float: none;
        }
    }
}

@media (max-width: 600px){
    .Home__bgText{
        top: 45%;
        h1{
            font-size: 28px;
        }
        h2{
            font-size: 15px;
        }
    }
}

@media (max-width: 500px){
    .Home__bgText{
        top: 45%;
        h1{
            font-size: 22px;
        }
        h2{
            font-size: 14px;
            margin-bottom: 7px;
            width: 325px;
        }
    }
}