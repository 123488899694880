.Menu__upperContainer {
  background-color: #131210;
}

.Menu__upper {
  width: 100%;
  max-width: 1800px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.Menu__upper__logoContainer {
  display: flex;
  flex: 0.9;
  max-width: 850px;
  justify-content: space-between;
  margin: auto 0 auto 30px;
  padding-bottom: 10px;
  flex-wrap: wrap;
  padding-right: 25px;
}
.Menu__upper__logoContainer img {
  height: 55px;
  display: block;
  margin-top: 10px;
  margin: auto;
  padding: 10px 20px;
}
.Menu__upper__contacts {
  margin-right: 30px;
  display: flex;
  justify-content: space-around;
  width: 40%;
  max-width: 550px;
}
.Menu__upper__contacts .contact {
  color: #BBB375;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.Menu__upper__contacts .contact img {
  width: 18px;
  height: 18px;
  margin-right: 20px;
}
.Menu__upper__contacts .contact a {
  color: #BBB375;
  text-decoration: none;
}
.Menu__upper__contacts .phone img {
  margin-top: 2px;
  margin-left: 5px;
}
.Menu__upper__contacts .row {
  height: 40%;
  width: 2px;
  background-color: #BBB375;
  margin: auto 0;
}

.Menu__bottom {
  background-color: #524D4D;
}
.Menu__bottom__container {
  max-width: 700px;
  height: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.Menu__bottom__container a {
  color: #fff;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 14px;
  margin: 10px 15px;
}

@media (max-width: 1230px) {
  .Menu__upper__logoContainer a:first-child {
    padding-bottom: 10px;
    border-bottom: 2px solid #BBB375;
  }
}
@media (max-width: 1050px) {
  .Menu__upper__contacts {
    width: 50%;
  }
}
@media (max-width: 850px) {
  .Menu__upper__contacts {
    width: 55%;
  }
}
@media (max-width: 800px) {
  .Menu__upper__contacts {
    width: 150px;
    margin: 15px 100px 0 15px;
    flex-direction: column-reverse;
  }
  .Menu__upper__contacts .row {
    display: none;
  }
}
@media (max-width: 750px) {
  .Menu__upper__contacts {
    display: none;
  }
  .Menu__bottom__container a {
    font-size: 11px;
  }
  .Menu__upper__logoContainer {
    height: 60px;
    flex-wrap: nowrap;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    padding-right: 0;
    margin: 0;
    align-items: center;
  }
  .Menu__upper__logoContainer a {
    width: 45%;
  }
  .Menu__upper__logoContainer img {
    padding: 0;
    height: 45px;
    width: 100%;
  }
  .Menu__upper__logoContainer a:first-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}/*# sourceMappingURL=menu.css.map */