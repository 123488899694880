.imageSlider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 80vw;
  height: 50vw;
  max-width: 800px;
  max-height: 450px;
  margin: 20px auto;
}
.imageSlider .slide {
  width: 0;
  opacity: 0;
  transition-duration: 1s ease;
}
.imageSlider .slide.active {
  width: 90vw;
  height: 50vw;
  max-width: 800px;
  max-height: 450px;
  opacity: 1;
  transition-duration: 1s;
  margin: 0 30px;
}
.imageSlider .slide.active .image {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.imageSlider .left-arrow,
.imageSlider .right-arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.imageSlider .left-arrow {
  left: 0;
}
.imageSlider .right-arrow {
  right: 0;
}

.slider__image {
  width: 100%;
  height: 35vw;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 20px 0 30px 0;
  max-height: 600px;
}

@media (max-width: 900px) {
  .imageSlider {
    width: 90vw;
  }
  .slider__image {
    width: 70vw;
    height: 100%;
  }
}
@media (max-width: 500px) {
  .imageSlider {
    width: 98vw;
  }
  .imageSlider .slide.active {
    margin: 0 25px;
  }
  .slider__image {
    width: 80vw;
  }
}
/* ImageSkeleton.css */
.skeleton-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.skeleton {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer-animation 1s infinite linear;
  border-radius: 4px;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes shimmer-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}/*# sourceMappingURL=imageSlider.css.map */